import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const PolicyTemplate = ({ data }) => {
	const { wpPolicy } = data;
	const { siteUrl } = data.site.siteMetadata;

	const finalTitle = wpPolicy.title
		.toLowerCase()
		.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

	return (
		<div className="bg-black">
			<Layout>
				<GatsbySeo
					title={`Our ${finalTitle} | Ninja Arena Eastbourne`}
					description={wpPolicy.title}
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}${wpPolicy.uri}`,
						title: `Our ${finalTitle} | Ninja Arena Eastbourne`,
						description: `${wpPolicy.title}`,
					}}
				/>

				<Container className="my-6 ">
					<Row>
						<Col>
							<h1 className="display-4 text-center  text-lg-start shojumaru  text-white pb-5">
								{wpPolicy.title}
							</h1>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="policy px-3 text-white px-lg-0">
								{parse(wpPolicy.content)}
							</div>
						</Col>
					</Row>
				</Container>
			</Layout>
		</div>
	);
};

export default PolicyTemplate;

export const blogData = graphql`
	query ($id: String!) {
		wpPolicy(id: { eq: $id }) {
			title
			content
			uri
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
